
import { Component, Input } from '@angular/core';
import { UGenericsService } from 'src/app/services/u-generics.service';
import { SessionService } from 'src/app/services/session.service';
import { NavHelperService } from 'src/app/services/nav-helper.service';

import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  language: any;
  selectedLanguage: any;

  public UserName: any = "";
  public UserID: any = "";

  public Version: any = "1.6.2";

  constructor(public ugs: UGenericsService,
    public session: SessionService,
    public navHelper: NavHelperService) {

    this.setNavbarUserName();

    var languageCode = localStorage.getItem('languageCode');
    if (!languageCode || (languageCode === 'null')) {
      languageCode = 'iw';
    }

    this.language = localStorage.getItem("KnownLanguages");
    if (this.language) {
      this.language = JSON.parse(this.language);
      if (this.language) {
        this.selectedLanguage = this.language.filter(function (item) { return item.id === languageCode; })[0];
      }
    }
    else {
      this.language = JSON.parse("[{ \"id\": \"iw\", \"name\": \"Hebrew\" }]");
      this.selectedLanguage = this.language[0];
    }

    this.ugs.adjastUserLanguage(languageCode, false);
  }

  onChange(event) {
    this.ugs.adjastUserLanguage(event.id, true);
    var elm_button: any = document.getElementsByClassName("navbar-toggler")[0];
    elm_button.click();
  }

  toggle() {
    var elm_button: any = document.getElementsByClassName("navbar-toggler")[0];
    elm_button.click();
  }

  public onLogout() {
    this.session.clearSession();
    this.navHelper.navigate(['/']);
    this.setNavbarUserName();
  }

  public onAbout() {
    $('#about-modal').modal('show');

    $('#send-sms-modal').on('shown.bs.modal', function () {
      $('#eid_send_sms_message').focus();
    })
  }

  public OnAboutDone() {
    $("#about-modal .close").click()
  }

  public setNavbarUserName() {
    this.UserName = this.session.getUserName();
    this.UserID = this.session.getUserAccountSysId();

    //var at = this.session.getUserName();
    //if (this.UserName !== at) {
    //  this.UserName = at;
    //  this.UserID = this.session.getUserAccountSysId();
    //}

    $("#eid_modal_user_name").text(this.UserName);
    $("#eid_modal_user_id").text(this.UserID);

    $("#eid_user_name").text(this.UserName);
    //$('.navbar').navbar("destroy");
    //$('.navbar').navbar();

    //$('li.userName')
    //    // get the span inside, and use `[0]` to get dom object
    //    .find('a')[0].text((at));


    //var aa: any = $(document).find('li.userName a')[0];
    //if (aa) {
    //  aa.text(at);
    //}
    //var bb = aa;
  }
}
