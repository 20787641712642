
import { Component } from '@angular/core';
import { UGenericsService } from 'src/app/services/u-generics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  title = 'app';

  constructor(public ugs: UGenericsService) {
    //var languageCode = localStorage.getItem('languageCode');
    //if (!languageCode || (languageCode === 'null')) {
    //  languageCode = 'iw';
    //}
    //this.ugs.adjastUserLanguage(languageCode, false); // TODO
  }
}
