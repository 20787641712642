import { Injectable } from '@angular/core';
//import { NavMenuComponent } from 'src/app/nav-menu/nav-menu.component';

// const sessionTokenKey = 'session_token';
// const logoKey = 'logo';

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  constructor(/*public navBar: NavMenuComponent*/) { }

  public isAuthValid(): boolean {
    return localStorage.getItem("SessionKey") ? true : false;
  }

  public saveSession(login: any) {
    localStorage.setItem("SessionKey", login.SessionKey);
    localStorage.setItem("UserName", login.UserProfile.UserName);
    localStorage.setItem("UserAccountSysId", login.UserProfile.UserAccountSysId);
    localStorage.setItem("SysContext", login.UserProfile.SysContext);
    localStorage.setItem("IsAllowViewReports", login.UserProfile.IsAllowViewReports);
  }

  public getToken(): string {
    return localStorage.getItem("SessionKey");
  }

  public getUserName(): string {
    return localStorage.getItem("UserName");
  }

  public getUserAccountSysId(): string {
    return localStorage.getItem("UserAccountSysId");
  }

  public getSysContext(): string {
    return localStorage.getItem("SysContext");
  }

  public IsAllowViewReports(): string {
    return localStorage.getItem("IsAllowViewReports");
  }

  public clearSession() {
    localStorage.setItem("SessionKey", '');
    localStorage.setItem("UserName", '');
    localStorage.setItem("UserAccountSysId", '');
    localStorage.setItem("SysContext", '');
  }

  public setSessionKey(sessionKey) {
    localStorage.setItem("SessionKey", sessionKey);
  }
}
