import { Component, AfterViewInit } from '@angular/core';
import { UGenericsService } from 'src/app/services/u-generics.service';
import { SessionService } from 'src/app/services/session.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

export class HomeComponent implements AfterViewInit {
  constructor(public ugs: UGenericsService, public session: SessionService) {
  }

  ngAfterViewInit(): void {
    this.ugs.setsScreenProperties();

    if (this.session.isAuthValid()) {
      $(document).find('li.serviceCall')[0].style.display = "block";
      $(document).find('li.logout')[0].style.display = "block";
      $(document).find('li.login')[0].style.display = "none";
    }
    else {
      $(document).find('li.serviceCall')[0].style.display = "none";
      $(document).find('li.logout')[0].style.display = "none";
      $(document).find('li.login')[0].style.display = "block";
    }
  }
}
//import { Component } from '@angular/core';

//@Component({
//  selector: 'app-home',
//  templateUrl: './home.component.html',
//})
//export class HomeComponent {
//}
