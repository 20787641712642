
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UGenericsService } from 'src/app/services/u-generics.service';
import { UDbService } from 'src/app/services/u-db.service';
import { BaseFormComponent } from 'src/app/templates/base-form.component';
import { TransactionService } from 'src/app/services/transaction.service';
import { SessionService } from 'src/app/services/session.service';

import * as $ from 'jquery';
declare var $: any;
import * as moment from 'moment';
declare function sprintf(a0?, a1?, a2?, a3?, a4?, a5?, a6?, a7?, a8?, a9?): any;

@Component({
  selector: 'app-customer-find',
  templateUrl: './customer-find.component.html',
  styleUrls: ['./customer-find.component.scss']
})

export class CustomerFindComponent extends BaseFormComponent implements OnInit, AfterViewInit, OnDestroy {
  public customerInfo = null;

  initDone: boolean = false;

  public customers: any = [];
  public selectedCustomer: any;

  constructor(http: HttpClient, router: Router,
    ugs: UGenericsService, udb: UDbService,
    public transactionSvc: TransactionService,
    public session: SessionService) {
    super(http, router, ugs, udb);
  }

  onChange(event) {
    this.DoCustomerFind();
  }

  public async DoCustomerFind() {
    var MemberType = 0;
    var phoneNumber = (this.session.getSysContext() === "4") ?
      this.selectedCustomer.SysId :
      $(document).find('#eid_phone_number')[0].value;

    if (this.selectedCustomer && (this.selectedCustomer.SysId !== 0)) {
      var customerName = this.selectedCustomer.Description;
      var customerID = this.selectedCustomer.SysId;
      var procedureType = this.ugs.queryParam("procedureType");
      this.router.navigate(['new-service-call'], { queryParams: { procedureType, customerName, customerID } });
      return;
    }
    else if (phoneNumber) {
      if (!this.udb.checkForLegalPhoneNumber_defacto(phoneNumber)) {
        return;
      }

      const result: any = await this.transactionSvc.fetchCustomer('{"param":"' + phoneNumber + '", "MemberType":"' + MemberType + '"}');
      if (!result) {
        return;
      }

      if (result.MemberInfo) {
        this.customerInfo = result.MemberInfo;
        var customerName = this.customerInfo.Name;
        var customerID = this.customerInfo.SysId;
        var procedureType = this.ugs.queryParam("procedureType");
        this.router.navigate(['new-service-call'], { queryParams: { procedureType, customerName, customerID } });
        return;
      }
    }

    var procedureType = this.ugs.queryParam("procedureType");
    var phoneNumber = $("#eid_phone_number").val();
    this.router.navigate(['customer-register'], { queryParams: { procedureType, phoneNumber } });
  }


  //=================================================================================
  ngOnInit(): void {
    var eid_to_remove = this.ugs.isMobileLayout() ? "eid_desktop" : "eid_mobile";
    var element = document.getElementById(eid_to_remove);
    if (element) element.parentNode.removeChild(element);

    super.ngOnInit();
  }

  //=================================================================================
  ngAfterViewInit(): void {
    this.ugs.setsScreenProperties();

    $('.cl_vendor').hide();
    $('.cl_customer').hide();

    $(document).find('li.serviceCall')[0].style.display = "block";
    $(document).find('li.logout')[0].style.display = "block";

    if (this.ugs.queryParam("procedureType") === "ServiceCalls") {
      $("#to_color").css("background-color", "#EFEFEF");
    }
    else {
      $("#to_color").css("background-color", "lightblue");
    }

    this.getCustomerList();
  }

  //=================================================================================
  public async getCustomerList() {
    if (this.session.getSysContext() !== "4") {
      $('.cl_customer').show();
      $('.cl_customer').focus();
      return;
    }

    $('.cl_vendor').show();
    $('.cl_vendor').focus();

    const result: any = await this.transactionSvc.fetchCustomerList();
    if (result) {
      this.customers = result.Entities;
    }
    //else {
    //  this.ugs.Loger(result.ErrorMessage)
    //}

    var SysId = 0;
    var Description = this.ugs.uTranslate('New_Customer');
    this.customers.splice(0, 0, { SysId, Description });
    this.selectedCustomer = this.customers[0];
  }

  //=================================================================================
  ngOnDestroy(): void {
  }

  onExit(): void {
    this.router.navigate(['service-call']);
  }
}
