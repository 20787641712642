import { Injectable } from '@angular/core';
import { BaseService, AsyncRes } from './base.service';
import { HttpClient } from '@angular/common/http';
//import { ItemResponse } from '../models/item-data';
//import { TranInfo } from '../models/tran-info';
//import { Seller } from '../models/seller';
//import { FetchTransaction } from '../models/fetch-transaction';
//import { Member } from '../models/member';
//import { GiftCardItem } from '../models/gift-card-item';
//import { SpecialDiscount } from '../models/special-discount';
//import { AccountModel } from '../models/account-model';
import { SessionService } from '../services/session.service';
import { UGenericsService } from '../services/u-generics.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(http: HttpClient,
    public session: SessionService,
    private ugs: UGenericsService) {
    //super(http, ugs);
  }

  public fetchVendor(): AsyncRes<string> {
    var SessionKey = this.session.getToken();
    return this.ugs.Post('CRMFetchVendors.aspx', {SessionKey});
  }

  public fetchCustomerList(): AsyncRes<string> {
    var SessionKey = this.session.getToken();
    return this.ugs.Post('CRMFetchCustomers.aspx', { SessionKey });
  }


  public CreatePDFReport(params: string): AsyncRes<string> {
    var paramsObject = JSON.parse(params);
    paramsObject.SessionKey = this.session.getToken();
    params = JSON.stringify(paramsObject);
    return this.ugs.Post('CRMCreatePDFReport.aspx', params);
  }


  public fetchCategories(): AsyncRes<string> {
    var SessionKey = this.session.getToken();
    return this.ugs.Post('CRMFetchFilters.aspx', { SessionKey });
  }

  public fetchTransactions(params: string): AsyncRes<string> {
    var paramsObject = JSON.parse(params);
    paramsObject.SessionKey = this.session.getToken();
    params = JSON.stringify(paramsObject);
    return this.ugs.Post('CRMFetchTickets.aspx', params);
  }

  public fetchCustomer(params: string): AsyncRes<string> {
    var paramsObject = JSON.parse(params);
    paramsObject.SessionKey = this.session.getToken();
    params = JSON.stringify(paramsObject);
    return this.ugs.Post('CRMRecognizeMember.aspx', params);
  }


  public fetchTicketTypes(TicketType: string): AsyncRes<string> {
    var SessionKey = this.session.getToken();
    return this.ugs.Post('CRMFetchTicketTypes.aspx', { TicketType, SessionKey });
  }


  //public fetchTicketRequestTypes(params: string): AsyncRes<string> {
  //  var paramsObject = JSON.parse(params);
  //  paramsObject.SessionKey = this.session.getToken();
  //  params = JSON.stringify(paramsObject);
  //  return this.ugs.Post('CRMFetchTicketRequestTypes.aspx', params);
  //}

  public TicketRegistration(Ticket: any): AsyncRes<string> {
    var SessionKey = this.session.getToken();
    var params = { Ticket, SessionKey };
    return this.ugs.Post('CRMTicketRegistration.aspx', JSON.stringify(params));
  }

  public TicketUpdate(Ticket: any): AsyncRes<string> {
    var SessionKey = this.session.getToken();
    var params = { Ticket, SessionKey };
    return this.ugs.Post('CRMTicketUpdate.aspx', JSON.stringify(params));
  }

  public MemberRegistration(params: any, SysId): AsyncRes<string> {
    params.SessionKey = this.session.getToken();
    var query = SysId ? 'CRMMemberUpdate.aspx' : 'CRMMemberRegistration.aspx';
    return this.ugs.Post(query, JSON.stringify(params));
  }

  public fetchTicket(TicketSysId: any): AsyncRes<string> {
    var SessionKey = this.session.getToken();
    var params = { TicketSysId, SessionKey };
    return this.ugs.Post('CRMTicketGet.aspx', JSON.stringify(params));
  }


  public TicketSendSMS(params: any): AsyncRes<string> {
    params.SessionKey = this.session.getToken();
    return this.ugs.Post('CRMTicketSendSMS.aspx', JSON.stringify(params));
  }

  public TicketAddConversation(params: any): AsyncRes<string> {
    params.SessionKey = this.session.getToken();
    return this.ugs.Post('CRMTicketAddConversation.aspx', JSON.stringify(params));
  }

  public TicketPrint(params: any): AsyncRes<string> {
    params.SessionKey = this.session.getToken();
    return this.ugs.Post('CRMTicketPrint.aspx', JSON.stringify(params));
  }

  public TicketHistory(params: any): AsyncRes<string> {
    params.SessionKey = this.session.getToken();
    return this.ugs.Post('CRMTicketFetchActions.aspx', JSON.stringify(params));
  }

  public SetLandingPageSessionKey(sessionKey) {
    this.session.setSessionKey(sessionKey);
  }


  //public UploadFile(formData: FormData): AsyncRes<string> {
  //  //params.SessionKey = this.session.getToken();
  //  return this.ugs.Post('CRMTicketUploadFile.aspx', { formData });
  //}

  //public TicketAddImage(params: any): AsyncRes<string> {
  //  params.SessionKey = this.session.getToken();
  //  return this.ugs.Post('CRMTicketAddImage.aspx', JSON.stringify(params));
  //}

//public suspendTransaction(): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>('suspendtransaction');
  //}

  //public cancelTransaction(): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>('canceltransaction');
  //}

  //public fetchSuspended(): AsyncRes<TranInfo[]> {
  //  return this.postGeneric<TranInfo[]>('fetchsuspendedtransactions');
  //}

  //public loadTransaction(tranNumber: number): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>('loadtransaction', `{"TranNumber":${tranNumber}}`);
  //}

  //public loadLastTransaction(): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>('loadlasttransaction');
  //}

  //public loadNextTransaction(): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>('loadnexttransaction');
  //}

  //public loadPreviousTransaction(): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>('loadpreviostransaction');
  //}

  //public fetchSellers(searchPhrase: string): AsyncRes<Seller[]> {
  //  return this.postGeneric<Seller[]>(`fetchsellers?inputData=${searchPhrase}`);
  //}

  //public assignSeller(sellerCode: string): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>(`assignSeller?sellerCode=${sellerCode}`);
  //}

  //public fetchMembers(searchPhrase: string): AsyncRes<Member[]> {
  //  return this.postGeneric<Member[]>(`fetchmember?inputData=${searchPhrase}`);
  //}

  //public assignMember(cardNumber: string): AsyncRes<ItemResponse> {
  //  return this.postGeneric<ItemResponse>(`assignmember?cardNumber=${cardNumber}&IsActivateCardSelected=true`);
  //}

  //public recognizeGiftCard(cardNumber: string): AsyncRes<AccountModel> {
  //  return this.postGeneric<any>(`recognizegiftcard`, `{"CardNumber":"${cardNumber}"}`);
  //}

  //public addGiftCardItems(giftCardItem: GiftCardItem) {
  //  return this.postGeneric<ItemResponse>(`addgiftcarditems`, giftCardItem);
  //}

  //public setSpecialDiscount(specialdiscount: SpecialDiscount) {
  //  if (!specialdiscount) {
  //    throw new Error('Invalid parameters.');
  //  }

  //  return this.postGeneric<ItemResponse>(`setspecialdiscount`, specialdiscount);
  //}

  controllerName(): string {
    return 'transactions';
  }
}
