
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UGenericsService } from 'src/app/services/u-generics.service';
import { UDbService } from 'src/app/services/u-db.service';
import * as $ from 'jquery';

declare function sprintf(a0?, a1?, a2?, a3?, a4?, a5?, a6?, a7?, a8?, a9?): any;

@Component({
	selector: 'app-base-form',
	templateUrl: './base-form.component.html',
})

export class BaseFormComponent implements OnInit {
	public  primary_table_name: string = '';
	public  primary_table_columns: any = [];
	public  splitDir: string = localStorage.getItem('direction');

  constructor(public http: HttpClient, public router: Router,
    public ugs: UGenericsService, public udb: UDbService) {

    //if (this.constructor.name !== 'LoginComponent') {
    //  if (this.ugs.deviceDetector.isMobile()) {
    //    this.ugs.Loger("Mobile interface is still under construction!", true);
    //    window.location.href = '';
    //    return;
    //  }
    //}
  }

	public getFormData(scData, autoUpdate, setNavBar) {
		this.udb.setNavigationBar(setNavBar);
		this.ugs.setsScreenProperties();

		this.udb.prepareDatasets(scData);
		this.udb.auto_update = autoUpdate; // Binding procedure should sync data with server
		this.udb.bindData(this);

		$('#eid_main_table tr td').click(this.mainTableClicked.bind(this));
		this.setMainTableCursor();
	}


	//=================================================================================
	public ngOnInit() {
	}


	//=================================================================================
	mainTableClicked(e) {
		if (!this.udb.onAboutToNavigate()) return;

		this.udb.record_position = e.currentTarget.parentNode.rowIndex - 1;
		this.udb.bindData();

		this.setMainTableCursor();
	}

	public setMainTableCursor() {
		var table: any = document.getElementById('eid_main_table');
		if (!table) return;
		var cells = table.getElementsByTagName('td');

		for (var i = 0; i < cells.length; i++) {
			cells[i].classList.remove('high-light');
		}

		cells = table.rows[this.udb.record_position + 1].getElementsByTagName('td');
		for (var i = 0; i < cells.length; i++) {
			cells[i].classList.add('high-light');
		}
	}
}

