
import { Injectable, Inject, EventEmitter, Output} from '@angular/core';
import { timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';


declare var $: any;

@Injectable()
export class UGenericsService {

  public pageLoad: boolean = false;

	@Output() public onUploadFinished = new EventEmitter();

	public msg_error: string = "";
	public msg_no_value: string = "";
	public msg_illegal_value: string = "";

	public current_language: string = "";
	public application_url: string = "";

	//===================================================
	constructor(public http: HttpClient, @Inject('BASE_URL') app_url,
		public deviceDetector: DeviceDetectorService,
		public translate: TranslateService) {
    this.webRequest(this, this.setLangs, "GetAppParams");
  }

  public Post(url: string, params: any = ''): Promise<any> {
    var url = this.getBaseUrl() + "/" + url;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    var TO = this.getBaseUrlTimeout();
    const promise = this.http.post(url, params, { headers }).pipe(timeout(TO)).toPromise();
    return promise.then((result: any) => {
      if (result.ErrorCode === 0) return result;

      if (result.ErrorMessage) {
        this.Loger(result.ErrorMessage, true);
      }
      else {
        this.Loger(`${this.uTranslate("Servie_Unknown_Error")} ${ result.ErrorCode}`, true);
      }
      return null;
    }).catch(error => {
      this.Loger(`${error.message}: ${url}`, true);
      return null;
    });
  }

  getBaseUrl() {
    let baseUrl = localStorage.getItem('EndpointUrl');
    baseUrl = this.fieldByPosition(baseUrl, 1, "|");
    return baseUrl;
  }

  getBaseUrlTimeout() {
    let baseUrl = localStorage.getItem('EndpointUrl');
    baseUrl = this.fieldByPosition(baseUrl, 2, "|");
    if (!baseUrl) return 30000;
    return Number(baseUrl) * 1000;
  }

  isLandscape() {
    return (screen.height <= screen.width);
  }

  isPortrait() {
    return (screen.height > screen.width);
  }

  isMobileLayout() {
    return this.deviceDetector.isMobile() ||
      (this.deviceDetector.isTablet() && this.isPortrait());
  }

  isDesktopLayout() {
    return this.deviceDetector.isDesktop() ||
      (this.deviceDetector.isTablet() && this.isLandscape());
  }

  setLangs(parameters) {
    const jsonParams = JSON.parse(parameters);
    localStorage.setItem('KnownLanguages', jsonParams.KnownLangs); // TODO
    localStorage.setItem('EndpointUrl', jsonParams.EndpointUrl); // TODO
  }

  //=================================================================================
  public getLocalStorageItem(itemName) {
    let item = localStorage.getItem(itemName);
    if (!item || item === "undefined") item = "";
    return item;
}

	//=================================================================================
	public setsScreenProperties(): void {
		$('body').append("<div id=eid_status_line></div>");

    window.addEventListener("load", function () {
      setTimeout(function () {
        window.scrollTo(0, 1);
      }, 0);
    });

    const self = this;

    this.pageLoad = true;

    $(window).on("orientationchange", function () {
      if (!self.pageLoad) window.location.reload();
      self.pageLoad = false;
    });

    $(document).click(function () {
			this.Loger("");
		}.bind(this));

    $(window).on('resize', function () {
      this.resizeWindow();
    }.bind(this));

		this.resizeWindow();
	}


	//=================================================================================
	public resizeWindow() {
    if (!this.deviceDetector || !this.deviceDetector.os) return;

    const orientation = screen.height > screen.width ? "portrait" : "landscape";

    //this.Loger(this.deviceDetector.browser + '; '
    //  + screen.height + '; '
    //  + screen.width + '; '
    //  + window.innerHeight + '; '
    //  + window.innerWidth, true);

    if ((this.deviceDetector.isDesktop() && window.innerWidth < 1200)
      || (this.deviceDetector.isMobile() && orientation.match('portrait'))
      || (this.deviceDetector.isTablet() && orientation.match('portrait'))) {
      var link = document.createElement('link');
      link.id = 'elm_mobile';
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = '/assets/css/styles-mobile.css';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    else {
      var element = document.getElementById("elm_mobile");
      if (element) element.parentNode.removeChild(element);
    }

		if (this.deviceDetector.os.match('Windows')) {
      if (this.deviceDetector.browser.match('Chrome')) {
        this.setWindows_Chrome(orientation);
      }
			return;
		}

    if (this.deviceDetector.os.match('iOS')) {
			if (this.deviceDetector.browser.match('Safari')) {
        this.setIOS_Safari(orientation);
			}
			if (this.deviceDetector.browser.match('Chrome')) {
        this.setIOS_Chrome(orientation);
			}
			return;
		}

		else if (this.deviceDetector.os.match('Android')) {
			if (this.deviceDetector.browser.match('Chrome')) {
        this.setAndroid_Chrome(orientation);
			}
			return;
		}
	}


	//=================================================================================
	queryParam(param_name) {
		var query = window.location.search.substring(1);
		var params = query.split("&");

		for (var i = 0; i < params.length; i++) {
			var param_value = params[i].split("=");

			if (param_value[0] === param_name) {
        return decodeURI(param_value[1]);
			}
		}

		return "";
	}


	//=================================================================================
	fieldByPosition(script, offset, stops) {
		if (script === "" || script === null || offset === 0) return "";

		var args = script.split(stops);

		if (args.length === 0 || args.length < offset) return "";

		return args[offset - 1].trim();
	};


	//===================================================
	Loger(message, do_alert?) {
		var status_line = document.getElementById('eid_status_line');
		while (status_line && status_line.firstChild) {
			status_line.removeChild(status_line.firstChild);
		}

		if (!message) {
			var _site_address: any =
          `<div id='eid_site_address' style='padding-top:1px';' dir='ltr'><a href='${this.uTranslate("Developer_Site")} '`
        + `target='_blank'> Site by: &nbsp; ${this.uTranslate("Developer_Name")}</a></div>`;
			if (status_line) status_line.innerHTML = _site_address;
			return;
		}

		if (status_line) {
			var _status_message: any = ("<div id='eid_status_message' dir='ltr'></div>");
			status_line.innerHTML = _status_message;
			var status_message = document.getElementById('eid_status_message');
			status_message.style.color = (message.indexOf("*** ") === 0) ? 'red' : 'black';
			message = message.replace("*** ", "");
			status_message.innerHTML = message.replace("<", "&lt").replace(">", "&gt");
		}

		window.console.log(message);
		if (do_alert) window.alert(message);
	}


	//==================================================================================
	ltrim(char, str) {
		if (str.slice(0, char.length) === char) {
			return this.ltrim(char, str.slice(char.length));
		} else {
			return str;
		}
	}


	//==================================================================================
	rtrim(char, str) {
		if (str.slice(str.length - char.length) === char) {
			return this.rtrim(char, str.slice(0, 0 - char.length));
		} else {
			return str;
		}
	}


  //==================================================================================
  getFileName(path) {
    return path.split(/[\\/]/).pop();  // extract file name from full path ...
  }

  //==================================================================================
  getBaseName(path) {
    var filename = this.getFileName(path);  // extract file name from full path ...

    var pos = filename.lastIndexOf(".");       // get last position of `.`

    if (pos != -1) filename = filename.substring(0, pos);

    return filename;
  }

	//==================================================================================
  getExtension(path) {
    var filename = this.getFileName(path);  // extract file name from full path ...

    var pos = filename.lastIndexOf(".");       // get last position of `.`

    if (filename === "" || pos < 1)            // if file name is empty or ...
			return "";                             //  `.` not found (-1) or comes first (0)

    return filename.slice(pos + 1);            // extract extension ignoring `.`
	}


	//=================================================================================
	public adjastUserLanguage(language: string, doReload: boolean) {
		var direction: string = 'ltr';
		document.getElementsByTagName('html')[0].removeAttribute('dir');
		//var elm_split = document.getElementsByTagName('as-split')[0];
		//if (elm_split) {
		//	elm_split.removeAttribute('dir');
		//	elm_split.removeAttribute('ng-reflect-dir');
		//}
		if (language === 'ar' || language === 'iw') { direction = 'rtl' }

		document.getElementsByTagName('html')[0].setAttribute('dir', direction);
		//if (elm_split) {
		//	elm_split.setAttribute('dir', direction);
		//	elm_split.setAttribute('ng-reflect-dir', direction);
		//}

		localStorage.setItem('direction', direction);
		localStorage.setItem('languageCode', language);

		if (direction == "rtl") {
			var link = document.createElement('link');
			link.id = 'elm_rtl';
			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.href = '/assets/css/styles-rtl.css';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		else {
			var element = document.getElementById("elm_rtl");
			if (element) element.parentNode.removeChild(element);
		}

		//if (this.current_language == language) return;
		this.current_language = language;

		this.translate
			.getTranslation(this.current_language)
			.subscribe(result => {

				this.translate.setDefaultLang(this.current_language);

				this.msg_error = this.uTranslate("Error");
				this.msg_no_value = this.uTranslate("No_Value");
				this.msg_illegal_value = this.uTranslate("Illegal_Value");
			});

		if (doReload) {
			window.location.reload();
			return;
		}
	}

	webRequest(caller, callback, request_type, param1?, param2?) {
		this.setSpinner(true);

		const httpOptions: any = {
			//headers: new HttpHeaders({
			//	'Content-Type': 'nosniff'
			//}),
			responseType: 'text',
			observe: 'response'
		};

		var query = this.application_url + "WebApi?"
			+ "request_type=" + request_type
			+ "&param1=" + param1
			+ "&param2=" + param2;

		this.http.post(query, "", httpOptions)
			.subscribe(
				response => {
          this.Loger(`<${request_type}> POST Request is successful.`);

					this.setSpinner(false);
					if (callback) callback.bind(caller)((response as any).body);
				},
				error => {
          this.Loger(`*** <${request_type}> error: <${error}>`);

					this.setSpinner(false);
					if (callback) callback.bind(caller)("");
				})
	}


	//=================================================================================
	uTranslate(keyword) {
		this.translate.get(keyword).subscribe((text: string) => { keyword = text; })
		return keyword;
	}

	public setSpinner(boolSet) {// on=true, off=false

		document.body.style.cursor = boolSet ? "wait" : "default";

		$('#eid_spinner').remove();
		if (!boolSet) return;

		const _spinner = "<div id='eid_spinner' class='spinner'>"
			+ "<i class='fa fa-spinner fa-spin fa-3x fa-fw'></i>"
			+ "<span class='sr-only'>Loading...</span>"
			+ "</div>";
		$('body').append(_spinner);
	}


  //processImage(file) {
  //  let baseUrl = `${ApiSettings.endpointUrl}/`;

  //  this.http.post(baseUrl + 'CRMTicketUploadFile.aspx', file).subscribe(
  //    (r) => { console.log('got r', r) }
  //  )
  //}

	//=================================================================================
	uploadFile(caller, callback, files, sessionKey, remoteFilePath?, existingUri?) {
    //this.setSpinner(true);

  //  var ext = file.name.split('.').pop();
		////if (ext) remoteFilePath += ("." + ext);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const fileToUpload = files[i] as File;

      formData.append(fileToUpload.name, fileToUpload, fileToUpload.name);
    }

    const baseUrl = this.getBaseUrl() + "/"; // `${ApiSettings.endpointUrl}/`;

    const httpOptions: any = {
      responseType: 'text',
      observe: 'response'
    };

    const query = baseUrl + "CRMTicketUploadFile.aspx" +
      "?SessionKey=" + sessionKey +
      "&TicketSysId=" + remoteFilePath +
      "&ExistingUri=" + existingUri;

    this.http.post(query, formData, httpOptions)
      .subscribe(
        () => {
          this.Loger("Upload POST Request is successful.");
          if (callback) callback.bind(caller)();
          //this.setSpinner(false);
        },
        error => {
          this.Loger(`*** <upload> error: ${error}`);
          if (callback) callback.bind(caller)();
          //this.setSpinner(false);
        })
	}

	//=================================================================================
  setIOS_Safari(orientation) {
    if (orientation.match("portrait")) {
			$('body').css({ 'max-height': '89vh' });
			$('.main_frame').css({ 'max-height': '75.5vh', 'min-height': '75.5vh', 'height': '75.5vh' });
			$('.lframe,.rframe').css({ 'max-height': '73.5vh', 'min-height': '73.5vh', 'height': '73.5vh' });
			$('.tframe').css({ 'max-height': '59.5vh', 'min-height': '59.5vh', 'height': '59.5vh' });
			$('#mapcanvas').css({ 'height': '59.5vh' });
		}
		else { // Landscape
			$('body').css({ 'max-height': '87vh' });
			$('.main_frame').css({ 'max-height': '66vh', 'min-height': '66vh', 'height': '66vh' });
			$('.lframe,.rframe').css({ 'max-height': '63vh', 'min-height': '63vh', 'height': '63vh' });
			$('.tframe').css({ 'max-height': '42vh', 'min-height': '42vh', 'height': '42vh' });
			$('#mapcanvas').css({ 'height': '42vh' });
		}
	}


	//=================================================================================
  setIOS_Chrome(orientation) {
    if (orientation.match("portrait")) {
			$('body').css({ 'max-height': '88vh' });
			$('.main_frame').css({ 'max-height': '75.5vh', 'min-height': '75.5vh', 'height': '75.5vh' });
			$('.lframe,.rframe').css({ 'max-height': '73.5vh', 'min-height': '73.5vh', 'height': '73.5vh' });
			$('.tframe').css({ 'max-height': '59.5vh', 'min-height': '59.5vh', 'height': '59.5vh' });
			$('#mapcanvas').css({ 'height': '59.5vh' });
		}
		else { // Landscape
			$('body').css({ 'max-height': '91vh' });
			$('.main_frame').css({ 'max-height': '69vh', 'min-height': '69vh', 'height': '69vh' });
			$('.lframe,.rframe').css({ 'max-height': '65vh', 'min-height': '65vh', 'height': '65vh' });
			$('.tframe').css({ 'max-height': '42vh', 'min-height': '42vh', 'height': '42vh' });
			$('#mapcanvas').css({ 'height': '42vh' });
    }
	}

	//=================================================================================
  setAndroid_Samsung(orientation) {
    if (orientation.match("portrait")) {
			$('body').css({ 'max-height': '95vh' });
			$('.main_frame').css({ 'max-height': '86vh', 'min-height': '86vh', 'height': '86vh' });
			$('.lframe,.rframe').css({ 'max-height': '84vh', 'min-height': '84vh', 'height': '84vh' });
			$('.tframe').css({ 'max-height': '70vh', 'min-height': '70vh', 'height': '70vh' });
			$('#mapcanvas').css({ 'height': '70vh' });
		}
		else { // Landscape
			$('body').css({ 'max-height': '95vh' });
			$('.main_frame').css({ 'max-height': '77vh', 'min-height': '77vh', 'height': '77vh' });
			$('.lframe,.rframe').css({ 'max-height': '74vh', 'min-height': '74vh', 'height': '74vh' });
			$('.tframe').css({ 'max-height': '50vh', 'min-height': '50vh', 'height': '50vh' });
			$('#mapcanvas').css({ 'height': '50vh' });
		}
	}

	//=================================================================================
  setAndroid_Chrome(orientation) {
    if (orientation.match("portrait")) {
      $('body').css({ 'max-height': '92vh' });
      $('.main_frame').css({ 'max-height': '81vh', 'min-height': '81vh', 'height': '81vh' });
      //$('.lframe,.rframe').css({ 'max-height': '79vh', 'min-height': '79vh', 'height': '79vh' });
      //$('.tframe').css({ 'max-height': '66vh', 'min-height': '66vh', 'height': '66vh' });
      //$('#mapcanvas').css({ 'height': '66vh' });
    }
    else {
      $('body').css({ 'max-height': '99vh' });
      $('.main_frame').css({ 'max-height': '85vh', 'min-height': '85vh', 'height': '85vh' });
      //$('.lframe,.rframe').css({ 'max-height': '60vh', 'min-height': '60vh', 'height': '60vh' });
      //$('.tframe').css({ 'max-height': '38vh', 'min-height': '38vh', 'height': '38vh' });
      //$('#mapcanvas').css({ 'height': '38vh' });
    }
	}
  setWindows_Chrome(orientation) {
    if (orientation.match("portrait")) {
      $('body').css({ 'max-height': '99vh' });
      $('.main_frame').css({ 'max-height': '87vh', 'min-height': '87vh', 'height': '87vh' });
      //$('.lframe,.rframe').css({ 'max-height': '79vh', 'min-height': '79vh', 'height': '79vh' });
      //$('.tframe').css({ 'max-height': '66vh', 'min-height': '66vh', 'height': '66vh' });
      //$('#mapcanvas').css({ 'height': '66vh' });
    }
    else {
      $('body').css({ 'max-height': '99vh' });
      $('.main_frame').css({ 'max-height': '86vh', 'min-height': '86vh', 'height': '86vh' });
      //$('.lframe,.rframe').css({ 'max-height': '60vh', 'min-height': '60vh', 'height': '60vh' });
      //$('.tframe').css({ 'max-height': '38vh', 'min-height': '38vh', 'height': '38vh' });
      //$('#mapcanvas').css({ 'height': '38vh' });
    }
  }
}

