import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
//import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root'
})

export class NavHelperService {
  //constructor(private router: Router,
  //            private locRouterService: LocalizeRouterService) { }

  constructor(private router: Router) { }

  public navigate(path: string | any[], extras?: NavigationExtras) {
    //const translated = this.locRouterService.translateRoute(path);

    if (typeof path === 'string') {
      this.router.navigateByUrl(path);
    } else {
      this.router.navigate(path, extras);
    }
  }
}
