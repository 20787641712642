
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
//import { AuthService } from 'src/app/services/auth.service';
import { NavMenuComponent } from 'src/app/nav-menu/nav-menu.component';
import { UGenericsService } from 'src/app/services/u-generics.service';
import { UDbService } from 'src/app/services/u-db.service';
import { BaseFormComponent } from 'src/app/templates/base-form.component';
import { SessionService } from 'src/app/services/session.service';

import * as $ from 'jquery';
declare var $: any;

import * as moment from 'moment';
declare function sprintf(a0?, a1?, a2?, a3?, a4?, a5?, a6?, a7?, a8?, a9?): any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseFormComponent implements OnInit, AfterViewInit {
  constructor(http: HttpClient, router: Router,
    ugs: UGenericsService, udb: UDbService,
    private sessionSvc: SessionService,
    public navBar: NavMenuComponent) {
    super(http, router, ugs, udb);
    navBar.onLogout();
  }

  //=================================================================================
  ngAfterViewInit(): void {
    this.ugs.setsScreenProperties();

    $(document).find('li.serviceCall')[0].style.display = "none";
    $(document).find('li.logout')[0].style.display = "none";
    $(document).find('li.user_name_label')[0].style.display = "none";
    $('#eid_name').focus();
  }

  //=================================================================================
  async onLogin() {
    const User = $('#eid_name').val()
    const Password = $('#eid_password').val()

    if (!User || !Password) {
      this.ugs.Loger(this.ugs.uTranslate("Invalid_Login_Attempt"), true);
      return;
    }

    const res = await this.ugs.Post('SystemLogin.aspx', { User, Password });
    if (res) {
      this.sessionSvc.saveSession(res);
      this.ClearCash();
      this.router.navigate(['service-call']); //, { queryParams: { SysContext } });
    }
    //else {
    //  this.ugs.Loger(this.ugs.uTranslate("Fail_To_Login"), true);
    //}
  }

  ClearCash() {
    localStorage.setItem('status', "");
    localStorage.setItem('phone', "");
    localStorage.setItem('callNumber', "");
    localStorage.setItem('vendor', "");
    localStorage.setItem('customer', "");
    localStorage.setItem('category', "");
    localStorage.setItem("primeTicketType", "");
  }
}
